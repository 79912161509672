import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var useCallPermissions = function (user) {
    var hasPermissionViewCall = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.CALLS, MethodCode.VIEW);
    var hasPermissionUpdateCall = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.CALLS, MethodCode.EDIT);
    var hasPermissionDeleteCall = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.CALLS, MethodCode.DELETE);
    var hasPermissionListCall = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.CALLS, MethodCode.LIST);
    var hasPermissionCreateCall = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.CALLS, MethodCode.CREATE);
    var hasPermissionViewCallUser = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.ACESS_USER_CALL, MethodCode.VIEW);
    return {
        hasPermissionViewCall: hasPermissionViewCall,
        hasPermissionUpdateCall: hasPermissionUpdateCall,
        hasPermissionDeleteCall: hasPermissionDeleteCall,
        hasPermissionListCall: hasPermissionListCall,
        hasPermissionCreateCall: hasPermissionCreateCall,
        hasPermissionViewCallUser: hasPermissionViewCallUser,
    };
};
export default useCallPermissions;
